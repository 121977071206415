@mixin respond($breakpoint){
    @if $breakpoint == 1900{
        @media only screen and (max-width: 1900px){ @content };
    }
    @if $breakpoint == 1600{
        @media only screen and (max-width: 1600px){ @content };
    }
    @if $breakpoint == 1400{
        @media only screen and (max-width: 1400px){ @content };
    }
    @if $breakpoint == 1200{
        @media only screen and (max-width: 1200px){ @content };
    }
    @if $breakpoint == 992{
        @media only screen and (max-width: 992px){ @content };
    }
    @if $breakpoint == 768{
        @media only screen and (max-width: 768px){ @content };
    }
    @if $breakpoint == 468{
        @media only screen and (max-width: 468px){ @content };
    }
}

//Transition
@mixin transition($transition) {
    -webkit-transition: $transition;
    -moz-transition: $transition;
    -ms-transition: $transition;
    -o-transition: $transition;
    transition: $transition;
}