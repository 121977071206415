//=======================================================================
// UNIVERSAL
//=======================================================================

html {
    // overflow-y: scroll; // scrollbar fix (prevent ugly left pull)

    ::selection {
        background-color: $baseColor;
        color: #231f20;
    }
}

body {
    overflow-x: hidden;
    font-size: 18px;
    color: #171812;

    * {
        font-family: 'Poppins', sans-serif $i;
    }

    &.rtl {
        * {
            font-family: 'Open Sans Hebrew', sans-serif $i;
        }
    }

    &.fluid-body {
        #main-component {
            >.container {
                max-width: 100%;
                padding: 0;

                .normal-container {
                    width: 100%;
                    max-width: 1140px;
                    padding: 0px 15px;
                    margin: 0 auto;
                }
            }
        }
    }

    ul.breadcrumb {
        padding: 8px 15px;
        background-color: transparent;
        border-radius: 0px;
        margin: 4px 0px;

        >li {
            +li {
                &:before {
                    color: #171812;
                    display: inline-block;
                    font-size: 12px;
                    width: 14px;
                }
            }
        }

        li {
            * {
                color: #171812;
                font-size: 16px;
            }

            a {
                text-decoration: underline;
            }

            .divider {
                display: none;
            }

            &[itemprop="itemListElement"] {

                &:first-of-type,
                &:nth-child(2) {
                    &:before {
                        display: none;
                    }
                }
            }
        }
    }
}

li:hover>a,
li>a,
#logo,
.fixed #logo,
.preloader,
#logo img,
.togglers h3:before,
ul.nav.menu>li a:after,
#ituran,
.id-101 .services .col-sm-4 a,
.border-image,
.product-info,
.hover-cont,
.relateditems a img {
    -webkit-transition: all 300ms ease-in-out $i;
    -moz-transition: all 300ms ease-in-out $i;
    -ms-transition: all 300ms ease-in-out $i;
    -o-transition: all 300ms ease-in-out $i;
    transition: all 300ms ease-in-out $i;
}

img {
    user-drag: none;
    user-select: none;
    -moz-user-select: none;
    -webkit-user-drag: none;
    -webkit-user-select: none;
    -ms-user-select: none;
    max-width: 100%;
}

.container {
    width: 100%;
    max-width: 1140px;
}

ul.nav.menu {
    padding: 0px;
    text-align: center;

    &>li {
        display: inline-block;
        position: relative;
        vertical-align: middle;
        padding: 0px 18px;

        &:first-child {
            padding-left: 0;
        }

        &:last-child {
            padding-right: 0;
        }

        &>ul {
            display: none;
            // display: block $i;
            position: absolute;
            background-color: #fff;
            min-width: 243px;
            box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
            padding: 6px 0px;
            z-index: 1;
            padding-top: 0px;
            text-align: left;
            left: 0;

            li {
                display: block;
                position: relative;
                background: #fff;
                line-height: 30px;
                margin-bottom: 0px;

                &:not(:last-child) {
                    border-bottom: 1px solid $baseColor;
                }

                a {
                    color: #383838;
                    padding: 6px 20px;
                    font-size: 16px;
                    font-weight: 400;
                    display: block;
                }

                &:hover,
                &.active {
                    background: $baseColor;

                    // a {
                    // 	color: #fff;
                    // }
                }
            }
        }

        &:hover {
            &>ul {
                display: block;
            }
        }

        &:not(:last-child) {
            &:after {
                position: absolute;
                top: 6px;
                right: 0;
                content: '|';
                font-size: 24px;
            }
        }

        a,
        .nav-header {
            font-size: 17px;
            font-weight: 500;
            color: #383838;
            position: relative;
            line-height: 30px;
            padding: 8px 0px;
            cursor: pointer;
            text-transform: uppercase;

            &:before {
                content: "";
                position: absolute;
                z-index: -1;
                left: 50%;
                right: 50%;
                bottom: 0;
                background: $baseColor;
                height: 3px;
                -webkit-transition-property: left, right;
                transition-property: left, right;
                -webkit-transition-duration: 0.3s;
                transition-duration: 0.3s;
                -webkit-transition-timing-function: ease-out;
                transition-timing-function: ease-out;
            }
        }

        a:hover {
            &:before {
                left: 0;
                right: 0;
            }
        }

        &.active,
        &.current {

            &>a,
            &>ul>li.active>a {
                &:before {
                    left: 0;
                    right: 0;
                }
            }
        }
    }
}

div[itemprop="articleBody"] {

    p,
    li {
        a {
            color: #fff;
            text-decoration: underline;
        }
    }
}

.fixed-top {
    position: fixed;
    width: 100%;
    top: 0;
    z-index: 999;
    margin: 0;
    background: #fff;
    height: 82px;
    padding: 13px 0px;
    -webkit-box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    -moz-box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    box-shadow: inset 0px -1px 0px 1px rgba(172, 172, 172, 1);
    display: flex;
    justify-content: space-around;
    align-items: center;

    @include respond(1900) {
        >div {
            width: fit-content;
            margin: 0 $i;
        }
    }

    .mobile-menu .con {
        margin-top: 0;
    }

    &.fixed {
        position: fixed;
        right: 0;
        top: 0;
        z-index: 999999;

        // .mobile-menu .con {
        // 	margin-top: 4px;
        // }
    }
}

body,
body.rtl {
    .fa {
        font-family: FontAwesome $i;
    }
}

.video-slider,
.video-slider-vertical {
    margin-top: 40px;

    .video_wrapper {
        width: 100%;
        margin: 0 auto;
        position: relative;
        background-color: #000;
        height: 300px;

        &.video_wrapper_full {
            padding-top: 315px;
        }

        .videoIframe {
            position: absolute;
            top: 0;
            bottom: 0;
            right: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background-color: transparent;
        }

        .videoPoster {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            border: 0;
            opacity: 1;
            background-size: cover;
            background-repeat: no-repeat;
            background-position: 50% 40%;
            -webkit-transition: opacity 800ms, height 0s;
            -moz-transition: opacity 800ms, height 0s;
            transition: opacity 800ms, height 0s;
            -webkit-transition-delay: 0s, 0s;
            -moz-transition-delay: 0s, 0s;
            transition-delay: 0s, 0s;

            &:before {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                width: 64px;
                height: 45px;
                background: url('../images/youtube_social_icon_red.png');
            }

            &:hover {
                cursor: pointer;
            }
        }

        &.videoWrapperActive {
            .videoPoster {
                opacity: 0;
                height: 0;
                -webkit-transition-delay: 0s, 800ms;
                -moz-transition-delay: 0s, 800ms;
                transition-delay: 0s, 800ms;
            }
        }
    }
}

#offcanvas {
    width: 100%;
    height: 100%;
    position: fixed;
    opacity: 0;
    z-index: -3;
    top: 0;
    right: 0%;
    background: transparent;

    .container {
        max-width: 100%;
        right: -100%;
        // background: rgba(0, 102, 165, 0.8);
        width: 100%;

        .search {
            input[name="searchword"] {
                border-radius: 50px 0px 0px 50px;
                padding-left: 10px;
                width: 73%;
                max-width: 1162px;
                border-right: 0;
                height: 55px;
                border: 2px solid #fff;
                background: $darkBlue;
                font-size: 26px;
                color: #000;
                float: left;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #fff;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #fff;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #fff;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #fff;
                }
            }
        }

        .close-btn {
            text-align: right;

            img {
                background: rgba(255, 255, 255, 0.7);
                border-radius: 50%;
            }
        }

        ul li {
            width: 100%;
            text-align: right;
            background: #fff;
            margin-bottom: 3px;
            border-bottom: 0px;
            border: 1px solid $baseColor;
            padding: 0 26px !important;
            // border-radius: 50px;

            &:after {
                display: none;
            }

            &.active {
                i.fa {
                    color: #fff $i;
                }
            }

            a {
                border-bottom: 0px;
                color: #383838;
                text-transform: uppercase;
                font-size: 26px;
                background-color: transparent;
                // border-radius: 50px;
                line-height: 40px;
                display: block;

                &:after {
                    display: none;
                }

                .fa-caret-down {
                    display: none $i;
                }
            }

            ul {
                width: 80%;
                background-color: #fff;
                min-width: 80%;
                position: relative;
                margin: 0 auto;

                li {
                    //background: @lightblue;
                    border: 0;

                    &:before {
                        display: none;
                    }

                    a {
                        font-size: 16px !important;
                        line-height: 20px;
                    }
                }
            }
        }
    }

    &.opened {
        z-index: 99999;
        background: rgba(252, 224, 175, .8);
        opacity: 1;
        overflow: scroll;
        padding-bottom: 200px;
        padding-top: 20px;
        right: 0;
        margin-top: 72px;
        position: fixed;
    }

    &.fixed-opened {
        margin-top: 72px;
    }

    .nav.menu {
        li {
            position: relative;

            &.parent {
                .dropdown-menu {
                    position: relative;
                    background-color: transparent;
                    border: 0px;
                    box-shadow: none;
                    width: 100%;
                }
            }

            a {
                white-space: inherit;
                display: inline-block;
            }

            i {
                cursor: pointer;
                padding: 14px;
                position: absolute;
                left: 0;
                color: #fff;
                z-index: 0;
                top: 0;
            }
        }
    }
}

.ltr {
    #offcanvas {
        .nav.menu {
            li {
                a {
                    display: block;
                    text-align: left;
                }

                i {
                    left: auto;
                    right: 0;
                }

                &.parent {
                    ul {
                        li {
                            a {
                                text-transform: capitalize;
                            }
                        }
                    }
                }
            }
        }
    }
}

#offcanvas .nav.menu li:not(.deeper) a {
    display: block;
}

a {
    color: $greenColor;

    &:hover {
        text-decoration: none;
        // color: #000 $i;
        // background-color: transparent $i;
    }
}

.nopadding {
    padding: 0;
}

.margin20 {
    margin-bottom: 20px;
}

.inline-block {
    display: inline-block;
    vertical-align: top;
}

.center-text {
    text-align: center;
}

.mobile-menu {
    text-align: right;
}

.preloader {
    position: fixed;
    top: 0;
    right: 0;
    z-index: 999999999999;
    background: #fff;
    width: 100vw;
    height: 100vh;
    opacity: 1;

    .progress {
        height: 6px;
        margin-top: 0;
    }

    .preloader-wrapper.big {
        width: 100%;
        height: 100%;
        max-width: 250px;
        max-height: 250px;
        position: absolute;
        top: calc(50% - 125px);
        left: calc(50% - 125px);
    }
}

.loaded {
    .preloader {
        opacity: 0;
        z-index: -1;
    }
}

.mobile-menu {
    text-align: right;
    height: 100%;
    // background: #fff;
    max-width: 100px;
    margin-right: 0;
    margin-left: auto;

    .con {
        width: auto;
        margin: 0 auto;
        -webkit-transition: all .7s ease;
        -moz-transition: all .7s ease;
        -ms-transition: all .7s ease;
        -o-transition: all .7s ease;
        transition: all .7s ease;
        cursor: pointer;
        display: inline-block;
        margin-top: 9px;
        margin-right: 20px;

        .bar {
            display: block;
            height: 5px;
            width: 50px;
            background: #fff;
            margin: 10px auto;
            border-radius: 10px;
            -webkit-transition: all .7s ease;
            -moz-transition: all .7s ease;
            -ms-transition: all .7s ease;
            -o-transition: all .7s ease;
            transition: all .7s ease;
        }

        .middle {
            margin: 0 auto;
        }

        &.clicked {
            .top {
                -webkit-transform: translateY(15px) rotateZ(45deg);
                -moz-transform: translateY(15px) rotateZ(45deg);
                -ms-transform: translateY(15px) rotateZ(45deg);
                -o-transform: translateY(15px) rotateZ(45deg);
                transform: translateY(15px) rotateZ(45deg);
            }

            .bottom {
                -webkit-transform: translateY(-15px) rotateZ(-45deg);
                -moz-transform: translateY(-15px) rotateZ(-45deg);
                -ms-transform: translateY(-15px) rotateZ(-45deg);
                -o-transform: translateY(-15px) rotateZ(-45deg);
                transform: translateY(-15px) rotateZ(-45deg);
            }

            .middle {
                width: 0;
            }
        }
    }
}

//Form Sent
.fox-container {
    .alert.alert-success {
        color: #fff $i;
        background-color: $bordo $i;
        border-color: #fff $i;
        text-align: center;
        font-size: 34px;
        margin-top: 200px;

        .close {
            color: #fff $i;
            text-shadow: none $i;
            opacity: 1 $i;
            font-size: 30px $i;
        }

        .fox-messages {
            text-shadow: none;
            color: #fff;
        }
    }
}

/*===============================
=            General            =
===============================*/
.borderd {
    margin: 0;
    text-align: center;
    position: relative;
    background: url(#{$images}title_borderd_line.png) no-repeat;
    background-position: 0% 50%;
    background-size: 100%;

    &:before,
    &:after {
        content: '';
        width: 14px;
        height: 14px;
        background-color: $baseColor;
        position: absolute;
        right: 0;
        top: calc(100% / 2 - 7px);
    }

    &:before {
        right: auto;
        left: 0;
    }

    span {
        font-size: 28px;
        color: #fff;
        display: inline-block;
        padding: 10px 25px;
        background-color: $baseColor;
        font-weight: 400;
        text-transform: uppercase;
        text-shadow: 1px 1px 1px #8c8c8c;
    }
}

.sq-divider {
    display: block;
    text-align: center;
    position: relative;
    padding: 30px 0px;
    max-width: 600px;
    margin: 0 auto;

    &:after {
        content: '';
        background-color: $baseColor;
        width: 100%;
        height: 2px;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }

    span {
        width: 14px;
        height: 14px;
        background-color: $baseColor;
        display: block;
        margin: 0 auto;
    }
}

/*=====  End of General  ======*/