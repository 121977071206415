/*===================================            Responsive            ===================================*/

@media (max-width: 1900px) {
    #header {
        height: 72px;

        .fixed-top {
            height: 72px;
            padding: 8px 0;

            @include respond(1200) {
                justify-content: end;
            }
        }

        .logo {
            margin-right: 46px;

            @include respond(1900) {
                margin: 0px 10px;
            }

            img {
                max-width: 180px;
            }
        }

        .search {
            margin-left: 30px;
            margin-right: 30px;
            max-width: 240px;

            @include respond(1900) {
                max-width: fit-content;
            }
        }

        .language-switcher {
            margin-right: 30px;
        }

        ul.nav.menu {
            >li {
                padding: 0 14px;

                a,
                .nav-header {
                    font-size: 17px;

                    @include respond(1400) {
                        font-size: 13px;
                    }
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    >.container {
                        .text-cont {
                            padding-right: 81px;
                        }
                    }
                }

                .hp-introtext {
                    >.row {
                        .col-sm-6.text-cont {
                            padding: 30px 60px;
                            line-height: 24px;
                            font-size: 16px;

                            h2 {
                                font-size: 40px;
                                line-height: 47px;
                                margin-bottom: 20px;
                            }

                            a {
                                margin-top: 30px;
                                height: 50px;

                                span {
                                    font-size: 30px;
                                }
                            }
                        }

                        .image-cont {
                            background-size: cover;
                            background-position: center center;

                            img {
                                opacity: 0;
                            }
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6.namer {
                                a {
                                    span {
                                        font-size: 68px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .rtl {
        #header {
            .logo {
                margin-right: 126px;

                @include respond(1900) {
                    margin: 0px 10px;
                }
            }
        }
    }
}

@media (max-width: 1600px) {
    // #header {
    //     .inline-block {
    //         &.search {
    //             display: none;
    //         }

    //         &.language-switcher {
    //             margin-left: 40px;
    //         }
    //     }
    // }

    #footer {
        >.container {
            .last-row {
                padding-bottom: 28px;
            }
        }
    }

    #offcanvas {
        .epc-module-search {
            .epc-search-box {
                margin-bottom: 20px;
            }
        }

        .nav.menu {
            li {
                a {
                    text-align: center $i;
                    font-size: 22px;
                }
            }
        }
    }

    /*.rtl {
		#header {
			.logo, .phone, .language-switcher {
				float: left;
			}

			.language-switcher {
				margin-top: 10px;
			}
		}
	}*/

    #offcanvas {
        .container {
            ul {
                li {
                    text-align: center $i;
                }
            }
        }
    }
}

@media (max-width: 1400px) {
    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    >.container {
                        &:before {
                            background-size: 100% 100%;
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6.namer {
                                a {
                                    span {
                                        font-size: 48px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    body {
        >.hp-verticals {
            >.container {
                >.row {
                    .col-sm-6.namer {
                        a {
                            span {
                                font-size: 28px;
                            }
                        }
                    }
                }
            }
        }

        &.view-item {
            #main-component {
                >.container {
                    >.contact-page {
                        #map {
                            height: 274px;
                        }
                    }
                }
            }
        }
    }

    .about-us {
        .epc-page {
            >.top {
                .parallax-container {
                    height: 389px;
                }
            }
        }
    }

    #footer {
        >.container {
            .first-row {
                max-width: 1170px;
            }
        }
    }
}

@media (max-width: 1200px) {
    .menu {
        height: 70px;
        padding-left: 20px;
        position: absolute;
        right: 0;
        top: 0;
        padding-top: 10px;
        background-color: $baseColor;

        #menu {
            .hidden-sm.hidden-xs {
                display: none;
            }

            .visible-sm.visible-xs {
                display: block $i;
            }
        }
    }

    body.rtl {
        #header {
            .language-switcher {
                margin-left: 30px;
            }
        }
    }

    .homepage,
    .about-us {
        #main-component {
            .epc-page {
                .hp-top {
                    >.container {
                        h4 {
                            top: 50%;
                            left: 40%;
                            font-size: 40px;
                            width: 60%;
                        }
                    }

                    >i {
                        left: 14px;
                        bottom: 8px;
                        font-size: 30px;
                    }
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 294px;

                    .parallax-container {
                        height: 294px;
                    }

                    >.container {
                        .text-cont {
                            padding-right: 30px;
                            padding-top: 30px;
                            max-width: 260px;

                            figcaption {
                                font-size: 34px;
                            }
                        }
                    }
                }

                .hp-introtext {
                    >.row {
                        .col-sm-6.text-cont {
                            a {
                                span {
                                    font-size: 30px;
                                    width: 100%;
                                }
                            }
                        }
                    }
                }


                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6.namer {
                                a {
                                    span {
                                        width: 80%;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-services {
        >.container {
            >.inline-block {
                .epc-item-image {
                    a {
                        figure {
                            img {
                                max-width: 110px;
                            }

                            figcaption {
                                font-size: 18px;
                                margin-top: 30px;
                            }
                        }
                    }
                }
            }
        }
    }

    .about-us {
        .epc-page {
            >.top {
                .parallax-container {
                    height: 360px;
                }
            }
        }
    }

    body {
        >.hp-verticals {
            >.container {
                >.row {
                    .col-sm-6 {
                        overflow: hidden;
                    }

                    .col-sm-6.namer {
                        a {
                            span {
                                font-size: 22px;
                            }
                        }
                    }
                }
            }
        }
    }

    .view-item {

        .project-page,
        .service-page,
        .vertical-page {
            .vertical-projects {
                .single-project {
                    a {
                        figure {
                            .cont {
                                height: 205px;
                            }
                        }
                    }
                }
            }
        }
    }

    #footer {
        >.container {
            .first-row {
                max-width: 1170px;
            }
        }
    }
}

@media (max-width: 992px) {
    #header {
        .logo {
            margin-right: 16px;
            margin-left: 16px;
        }

        .phone {
            display: none;
        }
    }

    #footer {
        >.container {
            .first-row {
                display: none;
            }

            .last-row {
                margin-top: 0;

                >div {
                    width: 100%;
                    padding: 0 $i;
                    float: none;
                    text-align: center;
                }

                .theguy {
                    text-align: center;
                }
            }
        }
    }

    #offcanvas {
        .phone.inline-block {
            display: block;
            width: 100%;
            text-align: center;
            font-size: 30px;
            background: #fff;
            padding: 6px;
            margin-top: 20px;

            figure {
                a {
                    color: #747474;

                    img,
                    figcaption {
                        display: inline-block;
                        vertical-align: middle;
                    }
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-introtext {
                    >.row {
                        .image-cont {
                            background-attachment: fixed;
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            .col-sm-6 {
                                overflow: hidden;

                                &.namer {
                                    a {
                                        span {
                                            padding: 15px 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .about-us {
        .epc-page {
            >.top {
                .parallax-container {
                    height: 280px;
                }
            }

            >.content {
                >.container {
                    .single-section {
                        .content {
                            padding: 20px 10px;
                        }
                    }
                }
            }
        }
    }

    .view-item {

        .vertical-page,
        .project-page,
        .service-page {

            .vertical-content,
            .service-content {
                padding: 0px 15px;

                >.row {
                    .col-sm-6 {
                        width: 100%;
                    }
                }
            }

            .related-services,
            .related-verticals {
                padding: 15px;

                .inner-cont {

                    .related-services-wrapper,
                    .related-verticals-wrapper {
                        ul {
                            li {
                                a {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }
            }

            .vertical-projects {
                .single-project {
                    width: 48%;
                }
            }
        }
    }
}

@media (max-width: 768px) {

    .homepage,
    .about-us {
        #main-component {
            .epc-page {
                .hp-top {
                    >.container {
                        h4 {
                            display: none;
                        }
                    }
                }
            }
        }
    }

    #header {
        .inline-block.language-switcher {
            margin-left: 10px;
            display: none;
        }
    }

    #offcanvas {
        .container {
            .language-switcher {
                display: block;
                margin-bottom: 20px;

                li {
                    margin: 0;
                    display: inline-block;
                    vertical-align: top;
                    width: 49%;
                    text-align: center;

                    &.lang-active {
                        background-color: #595959;

                        a {
                            color: #fff;
                            display: block;
                        }
                    }
                }
            }
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 194px;

                    .parallax-container {
                        height: 194px;
                    }

                    >.container {
                        .text-cont {
                            padding-right: 30px;
                            padding-top: 30px;
                            max-width: 160px;

                            figcaption {
                                font-size: 21px;
                            }
                        }
                    }
                }

                .hp-introtext {
                    >.row {
                        .image-cont {
                            display: none;
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            margin: 0;
                        }
                    }
                }
            }
        }
    }

    .hp-services {
        >.container {
            >.inline-block {
                width: 33%;
                margin-bottom: 30px;

                .epc-item-image {
                    margin-bottom: 0;
                }
            }
        }
    }

    .hp-verticals {
        >.container {
            >.row {
                width: 100% $i;

                .col-sm-6 {
                    float: inherit $i;

                    &.imager {
                        display: none;
                    }

                    &.namer {
                        height: 100px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
    }

    .about-us {
        .epc-page {
            >.top {
                .parallax-container {
                    height: 180px;
                }
            }
        }
    }

    .view-item {

        .vertical-page,
        .project-page,
        .service-page {

            .related-services,
            .related-verticals {
                .inner-cont {

                    .related-services-wrapper,
                    .related-verticals-wrapper {
                        ul {
                            li {
                                width: 49% $i;
                            }
                        }
                    }
                }
            }
        }
    }

    .rtl {
        #header {
            .zriha-logo {
                float: left;
            }

            .logo {
                margin-right: 0px;
                float: left;
            }
        }
    }
}

@media (max-width: 468px) {
    #b-acc-toolbarWrap div.b-acc_hide_toolbar {
        top: 13% $i;
    }

    #header {

        .language-switcher,
        .zriha-logo {
            display: none;
        }
    }

    #offcanvas {
        .phone.inline-block {
            font-size: 25px;
        }
    }

    .homepage {
        #main-component {
            .epc-page {
                .hp-top {
                    height: 124px;

                    .parallax-container {
                        height: 124px;
                    }

                    >.container {
                        .text-cont {
                            padding-top: 10px;
                            max-width: 110px;

                            figcaption {
                                font-size: 13px;
                            }
                        }
                    }
                }

                .hp-introtext {
                    >.row {
                        .col-sm-6.text-cont {
                            padding: 25px;
                            line-height: 24px;
                            font-size: 16px;

                            h2 {
                                font-size: 26px;
                                line-height: 35px;
                            }

                            a {
                                span {
                                    font-size: 20px;
                                }
                            }
                        }
                    }
                }

                .hp-verticals {
                    >.container {
                        >.row {
                            margin: 0;

                            .col-sm-6.namer {
                                a {
                                    span {
                                        font-size: 28px;
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-services {
        >.container {
            >.inline-block {
                width: 49%;
                margin-bottom: 30px;
            }
        }
    }

    .about-us {
        .epc-page {
            >.top {
                .parallax-container {
                    height: 120px;
                }
            }
        }
    }

    .borderd {
        background-size: 100%;

        span {
            font-size: 21px $i;
        }
    }

    .view-item {

        .vertical-page,
        .project-page,
        .service-page {

            .related-services,
            .related-verticals {
                .inner-cont {

                    .related-services-wrapper,
                    .related-verticals-wrapper {
                        ul {
                            li {
                                width: 100% $i;
                            }
                        }
                    }
                }
            }
        }
    }

    .let-us-help-you {
        font-size: 24px $i;
    }
}

/*=====  End of Responsive  ======*/