/**
 * desoslide - Take control of your slideshow with this powerful jQuery plugin
 * @version 2.2.0
 * @link https://github.com/sylouuu/desoslide
 * @license MIT
 */
.desoslide-wrapper {
  position: relative;
  text-align: center;
}
.desoslide-controls-wrapper {
  position: relative;
  float: right;
  height: 36px;
  line-height: 41px;
  bottom: 8px;
  z-index: 100;
  opacity: 1;
}
.desoslide-controls-wrapper a:hover {
  opacity: 0.7;
}
.desoslide-overlay {
  height: 36px;
  position: absolute;
  z-index: 10;
  text-align: left;
  color: white;
  font-weight: bold;
  background-color: black;
  padding: 8px;
  opacity: 0;
}
.desoslide-overlay > a {
  position: relative;
  top: -1px;
  color: white;
}
.desoslide-overlay .desoslide-caption-title {
  display: inline-block;
  position: relative;
  top: -1px;
  max-width: 90%;
  max-height: 24px;
  font-size: 14px;
  overflow: hidden;
}
.desoslide-controls {
  display: inline-block;
  background: url('../images/desoslide_controls.png') no-repeat 0 0;
}
.desoslide-controls.prev {
  width: 16px;
  height: 16px;
  background-position: 0 -54px;
}
.desoslide-controls.pause {
  width: 16px;
  height: 16px;
  background-position: 0 -18px;
}
.desoslide-controls.play {
  width: 16px;
  height: 16px;
  background-position: 0 -36px;
}
.desoslide-controls.next {
  width: 16px;
  height: 16px;
  background-position: 0 0;
}
