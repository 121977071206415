@font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold'), local('Poppins-SemiBold'),
        url('../fonts/hinted-Poppins-SemiBold.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-SemiBold.woff') format('woff'),
        url('../fonts/hinted-Poppins-SemiBold.ttf') format('truetype');
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight Italic'), local('Poppins-ExtraLightItalic'),
        url('../fonts/hinted-Poppins-ExtraLightItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-ExtraLightItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-ExtraLightItalic.ttf') format('truetype');
    font-weight: 200;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold'), local('Poppins-Bold'),
        url('../fonts/hinted-Poppins-Bold.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Bold.woff') format('woff'),
        url('../fonts/hinted-Poppins-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold Italic'), local('Poppins-ExtraBoldItalic'),
        url('../fonts/hinted-Poppins-ExtraBoldItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-ExtraBoldItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-ExtraBoldItalic.ttf') format('truetype');
    font-weight: 800;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Bold Italic'), local('Poppins-BoldItalic'),
        url('../fonts/hinted-Poppins-BoldItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-BoldItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light Italic'), local('Poppins-LightItalic'),
        url('../fonts/hinted-Poppins-LightItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-LightItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-LightItalic.ttf') format('truetype');
    font-weight: 300;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Black Italic'), local('Poppins-BlackItalic'),
        url('../fonts/hinted-Poppins-BlackItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-BlackItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-BlackItalic.ttf') format('truetype');
    font-weight: 900;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraLight'), local('Poppins-ExtraLight'),
        url('../fonts/hinted-Poppins-ExtraLight.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-ExtraLight.woff') format('woff'),
        url('../fonts/hinted-Poppins-ExtraLight.ttf') format('truetype');
    font-weight: 200;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Black'), local('Poppins-Black'),
        url('../fonts/hinted-Poppins-Black.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Black.woff') format('woff'),
        url('../fonts/hinted-Poppins-Black.ttf') format('truetype');
    font-weight: 900;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Italic'), local('Poppins-Italic'),
        url('../fonts/hinted-Poppins-Italic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Italic.woff') format('woff'),
        url('../fonts/hinted-Poppins-Italic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium'), local('Poppins-Medium'),
        url('../fonts/hinted-Poppins-Medium.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Medium.woff') format('woff'),
        url('../fonts/hinted-Poppins-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Medium Italic'), local('Poppins-MediumItalic'),
        url('../fonts/hinted-Poppins-MediumItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-MediumItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins ExtraBold'), local('Poppins-ExtraBold'),
        url('../fonts/hinted-Poppins-ExtraBold.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-ExtraBold.woff') format('woff'),
        url('../fonts/hinted-Poppins-ExtraBold.ttf') format('truetype');
    font-weight: 800;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin Italic'), local('Poppins-ThinItalic'),
        url('../fonts/hinted-Poppins-ThinItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-ThinItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-ThinItalic.ttf') format('truetype');
    font-weight: 100;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Regular'), local('Poppins-Regular'),
        url('../fonts/hinted-Poppins-Regular.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Regular.woff') format('woff'),
        url('../fonts/hinted-Poppins-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Thin'), local('Poppins-Thin'),
        url('../fonts/hinted-Poppins-Thin.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Thin.woff') format('woff'),
        url('../fonts/hinted-Poppins-Thin.ttf') format('truetype');
    font-weight: 100;
    font-style: normal;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins SemiBold Italic'), local('Poppins-SemiBoldItalic'),
        url('../fonts/hinted-Poppins-SemiBoldItalic.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-SemiBoldItalic.woff') format('woff'),
        url('../fonts/hinted-Poppins-SemiBoldItalic.ttf') format('truetype');
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: 'Poppins';
    src: local('Poppins Light'), local('Poppins-Light'),
        url('../fonts/hinted-Poppins-Light.woff2') format('woff2'),
        url('../fonts/hinted-Poppins-Light.woff') format('woff'),
        url('../fonts/hinted-Poppins-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
}

@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 300;
    src: url(../fonts/opensanshebrew-lightitalic-webfont.eot);
    src: url(../fonts/opensanshebrew-lightitalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-lightitalic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-lightitalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 300;
    src: url(../fonts/opensanshebrew-light-webfont.eot);
    src: url(../fonts/opensanshebrew-light-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-light-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-light-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 400;
    src: url(../fonts/opensanshebrew-italic-webfont.eot);
    src: url(../fonts/opensanshebrew-italic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-italic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-italic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 400;
    src: url(../fonts/opensanshebrew-regular-webfont.eot);
    src: url(../fonts/opensanshebrew-regular-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-regular-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-regular-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 700;
    src: url(../fonts/opensanshebrew-bolditalic-webfont.eot);
    src: url(../fonts/opensanshebrew-bolditalic-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-bolditalic-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-bolditalic-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 700;
    src: url(../fonts/opensanshebrew-bold-webfont.eot);
    src: url(../fonts/opensanshebrew-bold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-bold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-bold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: italic;
    font-weight: 800;
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot);
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
@font-face {
    font-family: 'Open Sans Hebrew';
    font-style: normal;
    font-weight: 800;
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot);
    src: url(../fonts/opensanshebrew-extrabold-webfont.eot?#iefix) format('embedded-opentype'),
    url(../fonts/opensanshebrew-extrabold-webfont.woff) format('woff'),
    url(../fonts/opensanshebrew-extrabold-webfont.ttf) format('truetype');
}
