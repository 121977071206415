body.rtl {
    ul:not(.browser-default) {
        padding-right: 0;
    }

    @media (min-width: 768px) {

        .col-sm-1,
        .col-sm-2,
        .col-sm-3,
        .col-sm-4,
        .col-sm-5,
        .col-sm-6,
        .col-sm-7,
        .col-sm-8,
        .col-sm-9,
        .col-sm-10,
        .col-sm-11,
        .col-sm-12 {
            float: right;
        }
    }

    .mfp-counter {
        direction: ltr;
    }

    #header,
    #main-component {
        .zriha-logo {
            margin-right: 20px;
            margin-left: 0px;
        }

        .phone {
            margin-right: 0px;

            img {
                margin-right: 0;
                margin-left: 13px;
            }

            figcaption {
                direction: ltr;
            }
        }

        .search {
            margin-left: 35px;
        }

        .language-switcher {
            margin-right: 80px;
            margin-left: 0;
        }

        .menu.inline-block {
            margin-right: 30px;
        }

        #menu,
        .services-menu {
            ul.nav.menu {
                >li {
                    &:not(:last-child) {
                        &:after {
                            right: auto;
                            left: -5px;
                        }
                    }

                    &:first-child {
                        padding-left: 26px;
                        padding-right: 0;
                    }

                    &:last-child {
                        padding-left: 0;
                        padding-right: 26px;
                    }

                    ul {
                        text-align: right;
                        left: auto;
                        right: 0;

                        ul {
                            right: 100%;
                        }
                    }
                }
            }
        }
    }

    .fixed-top {
        @include respond(1200) {
            justify-content: flex-end $i;
        }
    }

    #header,
    #offcanvas {
        .phone {
            figcaption {
                direction: ltr;
            }
        }

        .epc-module-search {

            .epc-search-box,
            .search-form {
                input {
                    padding-right: 10px;
                    padding-left: 0;
                }

                .btn {
                    right: auto;
                    left: 0;

                    img {
                        transform: rotateY(180deg);
                    }
                }
            }
        }
    }

    #footer {
        >.container {
            .first-row {
                ul.nav.menu {
                    text-align: right;
                }

                h5 {
                    border-left: 0;
                    border-right: 3px solid;
                }
            }

            .last-row {
                .col-sm-8 {
                    padding-left: 0px;
                    padding-right: 60px;
                }
            }
        }
    }

    &.contact-us {
        .sites-contact-info {
            text-align: right;
        }

        #main-component {
            >.container {
                >.epc-page {
                    >.contact-row {
                        >.row {
                            .contact-form {
                                .fox-container {
                                    .fox-column {
                                        .fox-item.fox-item-captcha {
                                            .fox-item-captcha-cnt {
                                                >img+div {
                                                    margin-left: 0;
                                                    margin-right: 6px;
                                                }
                                            }
                                        }

                                        .fox-item-attachments-btn-cnt {
                                            margin-left: 8px;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }
    }

    .hp-sites {
        >.inline-block {
            &:not(:last-child) {
                &:after {
                    right: auto;
                    left: 0;
                }
            }
        }
    }

    .hp-services {
        >.container {
            >.inline-block {
                &:not(:last-child) {
                    border-right: 0px;
                    border-left: 2px solid $baseColor;
                }
            }
        }
    }

    &.homepage {
        #main-component {
            .epc-page {
                .hp-introtext {
                    >.row {
                        .col-sm-6.text-cont {
                            h2 {
                                text-align: right;
                            }
                        }
                    }
                }
            }
        }
    }

    >.hp-verticals {
        >.container {
            >.row {

                &:nth-child(2),
                &:nth-child(6) {
                    .imager {
                        float: left;
                    }
                }

                &:nth-child(3) {
                    .namer {
                        float: left;
                    }
                }
            }
        }
    }

}