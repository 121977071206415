//=======================================================================
// CUSTOM
//=======================================================================
// background: url(#{$images}footer_bg.jpg) no-repeat;
// bottom: 0;
// left: 50%;
// background-color: #e8e6cd;
// transform: translate(-50%, -50%);

/*----------  Header  ----------*/
#header {
    height: 82px;

    .inline-block {
        vertical-align: middle;
    }

    .logo {
        margin-left: 16px;
        margin-right: 20px;
    }

    .zriha-logo {
        padding-right: 30px;
        padding-left: 30px;
        border-right: 1px solid #ababab;
        border-left: 1px solid #ababab;
        position: relative;
        height: 56px;
        margin-left: 20px;

        @include respond(1200) {
            margin: 0px 20px $i;
        }

        @include respond(768) {
            display: none;
        }

        a {
            display: block;
            margin-top: 10px;

            img {
                max-width: 140px;
            }
        }

    }

    .phone {
        margin-right: 30px;

        figcaption,
        img {
            display: inline-block;
            vertical-align: middle;
            font-size: 25px;
            color: #747474;

            @include respond(1900) {
                font-size: 23px;
            }

            @include respond(1600) {
                font-size: 21px;
            }
        }

        figcaption {
            @include respond(1600) {
                display: none;
            }
        }

        img {
            margin-right: 13px;

            @include respond(1600) {
                max-width: 27px;
            }
        }
    }

    .search {
        width: fit-content;
        margin-left: 20px;
        margin-right: 35px;

        @include respond(992) {
            display: none;
        }

        .search-form {
            form {
                .epc-search-box {
                    @include transition(all .3s linear);
                    width: 40px;

                    input {
                        border: 0;
                        outline: 0;
                        line-height: 35px;
                        height: 35px;
                        font-weight: 400;
                        margin-bottom: 0;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: #fff;
                            text-transform: uppercase;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: #fff;
                            text-transform: uppercase;
                        }
                    }

                    .btn {
                        position: absolute;
                        right: 0;
                        top: 0;
                        background-color: transparent;
                        outline: 0;
                        margin: 0;
                        padding: 0;
                        padding: 5px 7px;
                        box-shadow: none $i;
                    }

                    .epc-live-search {
                        position: absolute;
                        background: #fff;
                        padding: 10px;
                        width: 100%;
                    }

                    &.active {
                        width: 100%;
                        max-width: 140px;

                        input {
                            border: 1px solid #D5D5D5;

                            &::-webkit-input-placeholder {
                                /* Chrome/Opera/Safari */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &::-moz-placeholder {
                                /* Firefox 19+ */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &:-ms-input-placeholder {
                                /* IE 10+ */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }

                            &:-moz-placeholder {
                                /* Firefox 18- */
                                color: #c7c7c7;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }

    .language-switcher {
        margin-right: 30px;

        .language-switcher-inner {
            .mod-languages {
                .lang-inline {
                    li {
                        margin: 0;
                        padding: 0;
                        display: inline-block;
                        vertical-align: top;

                        a {
                            margin: 0;
                            padding: 4px;
                            font-size: 13px;
                            color: #595959;
                            display: block;
                            width: 58px;
                            text-align: center;
                        }

                        &.lang-active {
                            float: left;

                            a {
                                color: #fff;
                                background-color: #595959;
                            }
                        }
                    }
                }
            }
        }
    }

    .menu {
        #menu {
            //general.scss
        }
    }
}

#offcanvas {
    .zriha-logo {
        text-align: center;
        margin-bottom: 20px;
        background-color: #fff;
        padding: 10px 0px;

        img {
            max-width: 150px;
        }
    }

    .search {
        width: 316px;
        margin-left: 90px;
        margin-right: 35px;
    }

    .search-form,
    .epc-module-search {
        margin-bottom: 20px;

        .epc-search-box {
            background-color: #fff;

            >input {
                border: 1px solid #D5D5D5;
                outline: 0;
                line-height: 35px;
                height: 35px;
                font-weight: 400;
                margin-bottom: 0;

                &::-webkit-input-placeholder {
                    /* Chrome/Opera/Safari */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &::-moz-placeholder {
                    /* Firefox 19+ */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &:-ms-input-placeholder {
                    /* IE 10+ */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }

                &:-moz-placeholder {
                    /* Firefox 18- */
                    color: #c7c7c7;
                    text-transform: uppercase;
                }
            }

            .btn {
                position: absolute;
                right: 0;
                top: 0;
                background-color: transparent;
                outline: 0;
                margin: 0;
                padding: 0;
                padding: 5px 7px;
                box-shadow: none $i;
            }

            .epc-live-search {
                position: absolute;
                background: #fff;
                padding: 10px;
                width: 100%;
            }
        }
    }

    @include respond(1200) {
        .menu {
            position: relative;
            height: fit-content;
        }
    }
}

/*----------  End Of Header  ----------*/

//Main Homepage
.homepage,
.about-us {
    #main-component {
        .epc-page {
            .hp-top {
                height: 494px;
                background-size: cover;
                background-attachment: fixed;
                position: relative;

                @include respond(768) {
                    height: 200px;
                }

                .parallax-container {
                    position: absolute;
                    height: 494px;
                    width: 100%;
                    z-index: 9;

                    @include respond(768) {
                        height: 200px;
                    }

                    #hp-video {
                        display: block;
                        z-index: 2;
                        position: relative;

                        @include respond(768) {
                            width: 480px;
                            height: 200px;
                        }

                        @include respond(468) {
                            height: 124px;
                        }
                    }
                }

                >.container {
                    width: 100%;
                    max-width: 100%;
                    height: 100%;
                    padding: 0;
                    background: url(#{$images}homepage_top_image_squares.png) no-repeat;
                    background-size: cover;
                    background-position: 0% 0%;
                    z-index: 2;
                    position: relative;
                    text-align: right;

                    h4 {
                        position: absolute;
                        top: 90%;
                        left: 50%;
                        z-index: 3;
                        transform: translate(-50%, -50%);
                        color: #fff;
                        font-size: 50px;
                        font-weight: 300;
                        text-shadow: 2px 2px 2px #656565;
                        width: 100%;
                        text-align: center;
                    }

                    &:before {
                        content: '';
                        width: 100%;
                        max-width: 100%;
                        height: 100%;
                        padding: 0;
                        background: url(#{$images}homepage_top_image_blur.png) no-repeat;
                        background-size: cover;
                        background-position: 0% 0%;
                        z-index: 3;
                        position: absolute;
                        top: 0;
                        right: 0;
                    }

                    .text-cont {
                        max-width: 100%;
                        text-align: center;
                        padding-top: 71px;
                        padding-right: 171px;
                        position: relative;
                        z-index: 3;
                        font-size: 57px;
                        font-weight: 400;
                        color: #fff;
                        display: inline-block;
                    }
                }

                >i {
                    position: absolute;
                    left: 36px;
                    bottom: 28px;
                    padding: 10px;
                    font-size: 30px;
                    z-index: 9;
                    cursor: pointer;
                    color: $baseColor;
                    display: none;
                }
            }

            .hp-introtext {
                >.row {
                    margin-bottom: 0px;

                    .col-sm-6 {
                        padding: 0;
                        position: relative;
                        background-size: cover;

                        img {
                            width: 100%;
                        }

                        &.text-cont {
                            padding: 40px 80px;
                            text-align: justify;
                            color: #000;
                            line-height: 24px;

                            @include respond(768) {
                                text-align: inherit;
                            }

                            h2 {
                                font-size: 36px;
                                font-weight: 600;
                                color: $baseColor;
                                margin-top: 0;
                                max-width: 600px;
                                line-height: 54px;
                                text-align: left;
                                margin-bottom: 30px;
                                text-shadow: 1px 1px 1px #8c8c8c;
                            }

                            a {
                                display: block;
                                text-align: center;
                                margin-top: 40px;
                                border: 4px solid $baseColor;
                                height: 69px;
                                position: relative;
                                font-weight: 700;
                                background-color: #888;

                                span {
                                    font-size: 36px;
                                    color: $baseColor;
                                    position: absolute;
                                    top: 50%;
                                    left: 50%;
                                    transform: translate(-50%, -50%);
                                }
                            }
                        }

                        &.image-cont {
                            &:before {
                                content: '';
                                position: absolute;
                                left: 10px;
                                top: 10px;
                                background: url(#{$images}hp_history_lastrow_leftimage_numbertwo_squares.png) no-repeat;
                                width: 80px;
                                height: 80px;
                                z-index: 2;
                            }

                            &:after {
                                content: '';
                                position: absolute;
                                right: 25px;
                                bottom: 10px;
                                background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                                width: 141px;
                                height: 142px;
                                z-index: 2;
                            }
                        }
                    }
                }
            }

            .hp-verticals {
                >.container {
                    max-width: 100%;
                    padding: 0;

                    >.row {
                        margin-bottom: 0;

                        .col-sm-6 {
                            padding: 0;
                            position: relative;

                            img {
                                width: 100%;
                            }
                        }

                        .col-sm-6 {
                            &.namer {
                                background-color: $baseColor;

                                a {
                                    display: block;
                                    height: 100%;
                                    text-align: center;
                                    position: relative;

                                    span {
                                        position: absolute;
                                        top: 50%;
                                        left: 50%;
                                        transform: translate(-50%, -50%);
                                        font-size: 54px;
                                        font-weight: 400;
                                        color: #fff;
                                        padding: 25px 0px;
                                        border-top: 3px solid #fff;
                                        border-bottom: 3px solid #fff;
                                        text-transform: uppercase;
                                        text-shadow: 1px 1px 1px #8c8c8c;
                                    }
                                }
                            }

                            &.imager {
                                figure {
                                    height: 300px;
                                    position: relative;
                                    overflow: hidden;

                                    img {
                                        opacity: .7;
                                        transition: all .5s;
                                        // height: 100%;
                                        position: absolute;
                                        width: 100%;
                                        height: 420px;
                                        object-fit: cover;

                                        &:hover {
                                            opacity: 1;
                                        }
                                    }
                                }
                            }
                        }

                        &:nth-child(1) {
                            .imager {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 10px;
                                    top: 10px;
                                    background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                                    width: 200px;
                                    height: 197px;
                                    background-size: cover;
                                    z-index: 2;
                                    transform: rotate(270deg);
                                }
                            }
                        }

                        &:nth-child(2) {
                            .imager {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    right: 10px;
                                    bottom: 10px;
                                    background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                                    width: 141px;
                                    height: 142px;
                                    z-index: 2;
                                }
                            }
                        }

                        &:nth-child(3) {
                            .imager {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    right: 25px;
                                    top: 10px;
                                    background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                                    width: 141px;
                                    height: 142px;
                                    z-index: 2;
                                    transform: rotate(270deg);
                                }
                            }
                        }

                        &:nth-child(4) {
                            .imager {
                                &:before {
                                    content: '';
                                    position: absolute;
                                    left: 25px;
                                    bottom: 10px;
                                    background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                                    width: 200px;
                                    height: 197px;
                                    background-size: cover;
                                    z-index: 2;
                                    transform: rotate(180deg);
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

/*=====================================
=            About Us Page            =
=====================================*/

.about-us {
    #main-component {
        .epc-page {
            .hp-top>.container {
                z-index: 9;
                background: transparent;

                &:before {
                    display: none;
                }
            }
        }
    }

    .epc-page {
        >.top {
            position: relative;

            .parallax-container {
                height: 689px;

                +.container {
                    width: 100%;
                    max-width: 100%;
                    height: 100%;
                    padding: 0;
                    background: url(#{$images}homepage_top_image_squares.png) no-repeat;
                    background-size: cover;
                    background-position: 0% 0%;
                    position: absolute;
                    top: 0;
                    left: 0;
                    z-index: 2;
                }
            }
        }

        >.content {
            >.container {
                max-width: 1391px;
                margin-top: 45px;

                .single-section {
                    .content {
                        padding: 50px 90px;
                        text-align: justify;

                        @include respond(768) {
                            text-align: inherit;
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of About Us Page  ======*/

/*====================================
=            Service Page            =
====================================*/

.view-item {

    .service-page,
    .vertical-page,
    .project-page {

        .service-content,
        .vertical-content,
        .project-content {
            max-width: 1391px;
            margin: 0 auto;

            >h1 {
                margin-top: 60px;
                margin-bottom: 40px;
            }

            >.row {
                // margin: 0;

                .col-sm-6 {
                    line-height: 22px;

                    p {
                        &:first-of-type {
                            margin-top: 0;
                        }
                    }
                }
            }
        }

        .related-verticals,
        .related-services {
            background-color: $baseColor;
            padding-top: 36px;
            padding-bottom: 47px;

            .inner-cont {
                max-width: 1391px;
                margin: 0 auto;

                .borderd {
                    background-image: url(#{$images}title_borderd_line_white.png);
                    background-repeat: no-repeat;
                    margin-bottom: 50px;

                    &:before,
                    &:after {
                        background-color: #fff;
                    }

                    span {
                        color: $baseColor;
                        background-color: #fff;
                    }
                }

                .related-verticals-wrapper,
                .related-services-wrapper {
                    ul {
                        text-align: center;

                        li {
                            display: inline-block;
                            vertical-align: top;
                            width: 49%;
                            margin-bottom: 5px;
                            border: 1px solid #808183;
                            padding: 18px;
                            background-color: #fff;

                            a {
                                color: #7a7979;
                                text-transform: uppercase;
                                font-size: 24px;
                                display: block;
                                height: 100%;
                            }
                        }
                    }
                }

                .related-services-wrapper {
                    ul {
                        li {
                            width: 33%;
                            max-width: 421px;

                            a {
                                font-size: 24px;
                            }
                        }
                    }
                }
            }
        }

        .video-slider-wrapper {
            max-width: 1391px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 40px;

            >.row {
                margin-top: 5rem;

                @include respond(768) {
                    margin-left: 0;
                    margin-right: 0;
                }

                >.col-md-5 {
                    @include respond(992) {
                        margin-top: 3rem;
                    }
                }
            }

            .video-slider-vertical {
                margin: 0;

                .video_wrapper {
                    height: 40rem;
                }

                +h3 {
                    text-align: center;
                }
            }

            .video-slider-nav-vertical {
                max-height: 40rem;
                direction: ltr;

                .mCSB_scrollTools {
                    .mCSB_dragger {
                        .mCSB_dragger_bar {
                            background-color: rgba(249, 192, 170, 0.75);
                        }
                    }
                }

                .slick-slide {
                    padding: 0px 5px;
                    direction: rtl;
                    cursor: pointer;

                    &:nth-child(odd) {
                        >.row {
                            background-color: #b7b7b7;

                            h5 {
                                color: #fff;
                            }
                        }
                    }

                    >.row {
                        display: flex;
                        flex-wrap: wrap;

                        h5 {
                            font-weight: 400;
                            color: $baseColor;
                            font-size: 2rem;
                        }

                        img {
                            object-fit: cover;
                            width: 100%;
                            height: 100%;
                        }
                    }
                }
            }
        }

        .service-gallery {
            max-width: 1391px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 40px;

            .service-gallery-container {
                margin-top: 30px;
                position: relative;
                line-height: 0;
                -webkit-column-count: 4;
                -webkit-column-gap: 10px;
                -moz-column-count: 4;
                -moz-column-gap: 10px;
                column-count: 4;
                column-gap: 10px;

                .service-gallery-image {
                    width: 100%;
                    height: auto;
                    margin-bottom: 10px;

                    a {
                        position: relative;
                        display: block;
                        height: 100%;
                        overflow: hidden;

                        img {
                            width: 100%;
                            height: auto;
                            transition: all .3s linear;
                        }

                        .overlay {
                            position: absolute;
                            width: 100%;
                            height: 60px;
                            background-color: rgba(0, 0, 0, 0.4);
                            display: block;
                            bottom: 0;
                            opacity: 0;
                            -webkit-transition: all 300ms ease-in-out $i;
                            -moz-transition: all 300ms ease-in-out $i;
                            -ms-transition: all 300ms ease-in-out $i;
                            -o-transition: all 300ms ease-in-out $i;
                            transition: all 300ms ease-in-out $i;

                            .content {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                color: #fff;
                                font-size: 16px;
                                line-height: 22px;
                                padding: 4px 8px;
                                width: 100%;
                                text-align: center;
                                word-break: break-all;
                            }
                        }

                        &:hover {
                            .overlay {
                                opacity: 1;
                            }

                            img {
                                transform: scale(1.3);
                            }
                        }
                    }
                }
            }

            .let-us-help-you {
                margin-top: 40px;
                width: 100%;
                display: block;
                text-align: center;
                font-size: 36px;
                font-weight: 400;
                border: 2px solid $baseColor;
                color: $baseColor;
                padding: 13px;
            }
        }

        .vertical-projects {
            max-width: 1391px;
            margin: 0 auto;
            padding-top: 30px;
            padding-bottom: 40px;
            text-align: center;

            >.inner-cont {
                >.borderd {
                    margin-bottom: 30px;
                }
            }

            .single-project {
                width: 33%;
                margin-bottom: 5px;
                background-color: #fff;
                text-align: center;

                a {
                    figure {
                        .cont {
                            height: 305px;
                            position: relative;
                            border: 1px solid #cecece;
                            margin-bottom: 8px;

                            img {
                                position: absolute;
                                left: 50%;
                                top: 50%;
                                transform: translate(-50%, -50%);
                            }
                        }

                        figcaption {
                            font-size: 25px;
                            font-weight: 400;
                            color: #fff;
                            background-color: $baseColor;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }

    .service-page.item_with_childs {
        .service-content {
            .borderd {
                +.row {
                    margin-bottom: 60px;
                }
            }

            .sons-items-wrapper {
                >hr {
                    border-color: #ccc9c9;
                }

                >.row {
                    &:first-of-type {
                        .col-sm-6 {
                            dl {
                                dt {
                                    border-top: 1px solid $baseColor;
                                }
                            }
                        }
                    }

                    &:nth-of-type(even) {
                        .col-sm-6 {
                            &.text-justify {
                                float: left;
                            }
                        }
                    }

                    .col-sm-6 {
                        figure {
                            display: block;
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                        }

                        dl {
                            margin: 0;
                            color: #595959;

                            dt {
                                text-align: center;
                                border-bottom: 1px solid $baseColor;
                                font-weight: 400;
                                font-size: 2em;
                                padding: 5px 0px;
                                color: #595959;
                            }

                            dd {
                                margin: 0;
                                font-size: 1em;
                            }
                        }

                        .read-more {
                            text-align: center;
                            display: block;
                            width: 100%;
                            font-size: 1.25em;
                            color: #595959;
                            border: 1px solid $baseColor;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

.view-category {
    .service-page.item_with_childs {
        .service-content {
            .borderd {
                margin-top: 40px;

                +.row {
                    margin-bottom: 60px;
                }
            }

            .sons-items-wrapper {
                >hr {
                    border-color: #ccc9c9;
                }

                >.row {
                    &:first-of-type {
                        .col-sm-6 {
                            dl {
                                dt {
                                    border-top: 1px solid $baseColor;
                                }
                            }
                        }
                    }

                    &:nth-of-type(even) {
                        .col-sm-6 {
                            &.text-justify {
                                float: left;
                            }
                        }
                    }

                    .col-sm-6 {
                        figure {
                            display: block;
                            height: 100%;
                            width: 100%;
                            background-size: cover;
                            background-position: center;
                        }

                        dl {
                            margin: 0;
                            color: #595959;

                            dt {
                                text-align: center;
                                border-bottom: 1px solid $baseColor;
                                font-weight: 400;
                                font-size: 2em;
                                padding: 5px 0px;
                            }

                            dd {
                                margin: 0;
                                font-size: 1em;
                            }
                        }

                        .read-more {
                            text-align: center;
                            display: block;
                            width: 100%;
                            font-size: 1.25em;
                            color: #595959;
                            border: 1px solid $baseColor;
                            padding: 10px;
                        }
                    }
                }
            }
        }
    }
}

.ltr {
    .service-page.item_with_childs {
        .service-content {
            .sons-items-wrapper {
                >.row {
                    &:nth-of-type(2n) {
                        .col-sm-6.text-justify {
                            float: right;
                        }
                    }
                }
            }
        }
    }
}

/*=====  End of Service Page  ======*/


/*=======================================
=            Contact Us Page            =
=======================================*/

body.contact-us {
    #main-component {
        >.container {
            max-width: 100%;
            padding: 0;

            >.epc-page {
                #map {
                    height: 474px;
                }

                .borderd {
                    max-width: 1418px;
                    margin: 0 auto;
                    margin-top: 40px;
                    margin-bottom: 26px;
                }

                >.contact-row {
                    margin-bottom: 0;
                    background-color: #f5f5f5;
                    padding: 50px 20px;

                    >.row {
                        max-width: 1418px;
                        margin: 0 auto;

                        .contact-form {
                            .fox-container {
                                .fox-column {
                                    &.fox-column6 {
                                        width: 49%;
                                        margin: 0;
                                        margin-right: 6px;

                                        @include respond(768) {
                                            width: 100%;
                                        }
                                    }

                                    &.fox-column12 {
                                        min-height: auto;
                                    }

                                    .fox-item {
                                        float: none;
                                        width: 100%;
                                        margin-bottom: 6px;

                                        &.fox-item-text-area {
                                            margin-bottom: 0;
                                        }

                                        &.fox-item-captcha {
                                            margin-bottom: 12px;

                                            .fox-item-captcha-cnt {
                                                max-width: 100%;

                                                >img {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    border: 1px solid #c8c8c8;

                                                    +div {
                                                        display: inline-block;
                                                        vertical-align: top;
                                                        width: calc(100% - 156px);
                                                        margin-left: 6px;
                                                    }
                                                }
                                            }
                                        }

                                        &.fox-item-attachments {

                                            label,
                                            span {
                                                font-size: 16px;
                                                font-weight: 400;
                                            }

                                            >.controls {
                                                text-align: center;

                                                >div,
                                                >span {
                                                    display: inline-block;
                                                    vertical-align: top;
                                                    width: 46%;

                                                    @include respond(768) {
                                                        width: 100%;
                                                        display: block;
                                                        max-width: 100% $i;
                                                    }
                                                }

                                                >div {
                                                    &:first-of-type {
                                                        max-width: 20%;
                                                    }

                                                    .qq-upload-button-caption {
                                                        line-height: 46px;
                                                        padding: 0px 10px;
                                                    }
                                                }

                                                >span {
                                                    max-width: 30%;
                                                }

                                                .btn {
                                                    margin-top: 0;
                                                }
                                            }
                                        }

                                        .controls {
                                            float: none;
                                            width: 100%;

                                            input,
                                            textarea,
                                            .btn {
                                                width: 100%;
                                                height: 48px;
                                                background-color: #fff;
                                                border: 1px solid #c8c8c8;
                                                color: #858585;
                                                padding: 0px 25px;

                                                &::-webkit-input-placeholder {
                                                    /* Chrome/Opera/Safari */
                                                    color: #858585;
                                                }

                                                &::-moz-placeholder {
                                                    /* Firefox 19+ */
                                                    color: #858585;
                                                }

                                                &:-ms-input-placeholder {
                                                    /* IE 10+ */
                                                    color: #858585;
                                                }

                                                &:-moz-placeholder {
                                                    /* Firefox 18- */
                                                    color: #858585;
                                                }
                                            }

                                            textarea {
                                                padding-top: 15px;
                                                height: 156px !important;
                                            }

                                            .btn {
                                                margin: 0;
                                                padding: 0;
                                                outline: 0;
                                                box-shadow: none;
                                                border-radius: 0px;
                                                background-color: $baseColor;
                                                margin-top: -6px;

                                                span {
                                                    color: #383838;
                                                    font-size: 21px;
                                                }
                                            }
                                        }
                                    }
                                }
                            }

                            .sf-form {
                                .row {
                                    .col-sm-6 {

                                        select {
                                            display: block;
                                            margin-bottom: 8px;
                                            height: 50px !important;
                                        }

                                        input,
                                        textarea,
                                        .btn,
                                        select {
                                            width: 100%;
                                            height: 48px;
                                            background-color: #fff;
                                            border: 1px solid #c8c8c8;
                                            color: #858585;
                                            padding: 0 10px;

                                            &::-webkit-input-placeholder {
                                                /* Chrome/Opera/Safari */
                                                color: #858585;
                                            }

                                            &::-moz-placeholder {
                                                /* Firefox 19+ */
                                                color: #858585;
                                            }

                                            &:-ms-input-placeholder {
                                                /* IE 10+ */
                                                color: #858585;
                                            }

                                            &:-moz-placeholder {
                                                /* Firefox 18- */
                                                color: #858585;
                                            }
                                        }

                                        textarea {
                                            padding-top: 15px;
                                            height: 109px !important;
                                            margin-bottom: 7px;
                                        }

                                        .btn {
                                            margin: 0;
                                            padding: 0;
                                            outline: 0;
                                            box-shadow: none;
                                            border-radius: 0px;
                                            background-color: $baseColor;
                                            margin-top: -6px;
                                            color: #383838;
                                            font-size: 21px;
                                        }
                                    }
                                }
                            }
                        }

                        .sites-contact-info {
                            >span:not(:last-of-type) {
                                margin-bottom: 4px;
                            }

                            >p strong {
                                margin: 22px 0;
                            }
                        }
                    }
                }
            }
        }
    }

    .sites-contact-info {
        text-align: left;

        >span {
            display: block;
            position: relative;
            font-size: 16px;
            color: #383838;
            max-width: 225px;
            margin: 0 auto;

            &:not(:last-of-type) {
                margin-bottom: 12px;
            }

            a {
                color: #383838;
                text-decoration: underline;
            }

            &:before {
                content: '';
                width: 20px;
                height: 24px;
                position: relative;
                display: inline-block;
                vertical-align: text-bottom;
                margin-right: 6px;
            }

            &.phone {
                &:before {
                    background: url(#{$images}sites_contact_phone.png) no-repeat;
                }
            }

            &.fax {
                &:before {
                    background: url(#{$images}sites_contact_fax.png) no-repeat;
                }
            }

            &.email {
                &:before {
                    background: url(#{$images}sites_contact_email.png) no-repeat;
                }
            }
        }

        >p {
            font-size: 16px;
            color: #383838 !important;
            max-width: 225px !important;
            margin: 0 auto !important;

            strong {
                margin: 30px 0px;
                display: block;
            }
        }

        >a {
            font-size: 21px;
            font-weight: 400;
            color: #383838;
            display: block;
            text-align: center;
            padding: 7px 0px;
            border: 1px solid #c8c8c8;
            max-width: 300px;
            margin: 30px auto;
            background-color: $baseColor;
        }
    }
}

/*=====  End of Contact Us Page  ======*/


/*==============================
=            Footer            =
==============================*/

#footer {
    >.container {
        max-width: 100%;
        padding: 0;

        .first-row {
            max-width: 1270px;
            margin: 0 auto;
            margin-top: 50px;

            >ul {
                width: calc(100% / 6);
                margin-right: -4px;
                text-align: left;
                padding: 0px 15px 0px 15px;
                display: inline-block;
                vertical-align: top;

                h5 {
                    font-weight: 400;
                    color: #878789;
                    font-size: 15px;
                    text-transform: uppercase;
                    padding: 15px;
                    margin-bottom: 30px;
                    border-left: 3px solid;
                }

                &.zriha {
                    h5 {
                        border-color: #fd0 !important;
                    }
                }

                &.metalitec {
                    h5 {
                        border-color: #ccd4a8 !important;
                    }
                }

                &.sunrise {
                    h5 {
                        border-color: #f9c0aa !important;
                    }
                }

                &.maalot {
                    h5 {
                        border-color: #fce0af !important;
                    }
                }

                &.electo {
                    h5 {
                        border-color: #b6ebea !important;
                    }
                }

                &.seatara {
                    h5 {
                        border-color: #c0d6d6 !important;
                    }
                }

                li {
                    padding: 0;
                    display: block;
                    margin: 0;
                    margin-bottom: 8px;

                    &:after {
                        display: none;
                    }

                    a {
                        color: #858688;
                        font-size: 13px;
                        font-weight: 400;
                        padding: 0;
                        display: block;
                        margin: 0;
                        text-transform: capitalize;

                        &:before {
                            display: none;
                        }
                    }

                    ul {
                        display: none;
                    }
                }
            }
        }

        .last-row {
            margin: 0;
            margin-top: 55px;
            padding-bottom: 48px;
            padding-left: 45px;
            padding-right: 44px;
            font-size: 16px;
            color: #0c0c0c;

            .col-sm-8 {
                padding-left: 0;

                .zriha-logo {
                    max-width: 174px;
                    padding-right: 30px;
                    padding-left: 30px;
                    border-right: 1px solid #ababab;
                    border-left: 1px solid #ababab;
                    position: relative;
                    height: 56px;
                    margin-right: 40px;
                    padding-top: 14px;

                    a {
                        display: block;
                        margin-top: 15px;

                        img {
                            max-width: 100px;
                        }
                    }

                }

                p {
                    margin-top: 8px;
                }
            }

            .theguy {
                text-align: right;
            }
        }
    }
}

/*=====  End of Footer  ======*/

//Search Page
.view-search {
    .search {
        h1 {
            font-size: 50px;
        }

        * {
            color: #000;
        }

        #searchForm {
            display: none;
        }

        .search-results {
            .result-title {
                font-size: 27px;
                text-decoration: underline;
            }

            .result-category {
                display: none;
            }
        }
    }
}

/*================================================
=            Repeatable Site Elements            =
================================================*/

.hp-services {
    border-top: 1px solid $baseColor;
    border-bottom: 1px solid $baseColor;

    >.container {
        text-align: center;
        max-width: 100%;
        padding: 40px 0px;

        >.inline-block {
            display: inline-block;
            vertical-align: top;
            margin-bottom: 0;
            width: calc(100% / 5);
            max-width: 330px;
            margin-right: -4px;

            &:not(:last-child) {
                border-right: 2px solid $baseColor;
            }

            .epc-item-image {
                width: 100%;

                a {
                    display: block;

                    figure {
                        figcaption {
                            font-size: 27px;
                            font-weight: 400;
                            color: #000;
                            margin-top: 50px;
                            text-transform: uppercase;
                            display: block;
                            max-width: 200px;
                            margin: 0 auto;
                            margin-top: 40px;
                            line-height: 32px;
                        }
                    }
                }
            }
        }
    }
}

body {
    >.hp-verticals {
        >.container {
            max-width: 100%;
            padding: 0;

            >.row {
                margin-bottom: 0;
                display: inline-block;
                width: 50%;
                margin: 0;
                margin-right: -4px;
                vertical-align: top;

                .col-sm-6 {
                    padding: 0;
                    position: relative;
                    max-height: 207px;

                    img {
                        width: 100%;
                    }
                }

                .col-sm-6 {
                    &.namer {
                        background-color: $baseColor;

                        a {
                            display: block;
                            height: 100%;
                            text-align: center;
                            position: relative;

                            span {
                                position: absolute;
                                top: 50%;
                                left: 50%;
                                transform: translate(-50%, -50%);
                                font-size: 38px;
                                letter-spacing: 10px;
                                font-weight: 400;
                                color: #fff;
                                padding: 20px 0;
                                border-top: 1px solid #fff;
                                border-bottom: 1px solid #fff;
                                text-transform: uppercase;
                                width: 330px;
                                max-width: 100%;
                            }
                        }
                    }

                    &.imager {
                        figure {
                            height: 100%;

                            img {
                                opacity: .7;
                                transition: all .5s;
                                height: 100%;
                                object-fit: cover;

                                &:hover {
                                    opacity: 1;
                                }
                            }
                        }
                    }
                }

                &:nth-child(1) {
                    .imager {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 10px;
                            top: 10px;
                            background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                            width: 120px;
                            height: 117px;
                            background-size: cover;
                            z-index: 2;
                            transform: rotate(270deg);
                        }
                    }
                }

                &:nth-child(2),
                &:nth-child(6) {
                    .imager {
                        float: right;

                        &:before {
                            content: '';
                            position: absolute;
                            right: 10px;
                            bottom: 10px;
                            background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                            width: 101px;
                            height: 102px;
                            background-size: cover;
                            z-index: 2;
                        }
                    }
                }

                &:nth-child(3) {
                    .namer {
                        float: right;
                    }

                    .imager {
                        &:before {
                            content: '';
                            position: absolute;
                            right: 25px;
                            top: 10px;
                            background: url(#{$images}hp_history_firstrow_leftimage_squares.png) no-repeat;
                            width: 101px;
                            height: 102px;
                            background-size: cover;
                            z-index: 2;
                            transform: rotate(270deg);
                        }
                    }
                }

                &:nth-child(4) {
                    .imager {
                        &:before {
                            content: '';
                            position: absolute;
                            left: 25px;
                            bottom: 10px;
                            background: url(#{$images}hp_history_lastrow_leftimage_squares.png) no-repeat;
                            width: 120px;
                            height: 117px;
                            background-size: cover;
                            z-index: 2;
                            transform: rotate(180deg);
                        }
                    }
                }
            }
        }
    }

    >.bottom-contact {
        padding: 40px 0px;

        h4,
        h4 a {
            text-align: center;
            font-size: 32px;
            color: #171812;
        }

        .fox-container {
            .fox-column6 {
                width: 48% $i;

                @include respond(768) {
                    width: 100% $i;
                }
            }

            .fox-item {
                &.fox-item-submit {
                    .controls {
                        margin-top: 6px $i;
                        width: 100% $i;
                    }
                }
            }
        }

        .sf-form {
            .row {
                .col-sm-6 {

                    select {
                        display: block;
                        margin-bottom: 8px;
                        height: 50px !important;
                    }

                    input,
                    textarea,
                    .btn,
                    select {
                        width: 100%;
                        height: 48px;
                        background-color: #fff;
                        border: 1px solid #c8c8c8;
                        color: #858585;
                        padding: 0 10px;

                        &::-webkit-input-placeholder {
                            /* Chrome/Opera/Safari */
                            color: #858585;
                        }

                        &::-moz-placeholder {
                            /* Firefox 19+ */
                            color: #858585;
                        }

                        &:-ms-input-placeholder {
                            /* IE 10+ */
                            color: #858585;
                        }

                        &:-moz-placeholder {
                            /* Firefox 18- */
                            color: #858585;
                        }
                    }

                    textarea {
                        padding-top: 15px;
                        height: 109px !important;
                        margin-bottom: 7px;
                    }

                    .btn {
                        margin: 0;
                        padding: 0;
                        outline: 0;
                        box-shadow: none;
                        border-radius: 0px;
                        background-color: $baseColor;
                        margin-top: -6px;
                        color: #383838;
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

.let-us-help-you {
    margin-top: 40px;
    width: 100%;
    display: block;
    text-align: center;
    font-size: 36px;
    font-weight: 400;
    border: 2px solid $baseColor;
    color: $baseColor;
    padding: 13px;
}

.fox-container {
    .fox-column {
        &.fox-column6 {
            width: 49%;
            margin: 0;
            margin-right: 6px;
        }

        &.fox-column12 {
            min-height: auto;
        }

        .fox-item {
            float: none;
            width: 100%;
            margin-bottom: 6px;

            .controls {
                float: none;
                width: 100%;

                input,
                textarea,
                .btn {
                    width: 100% $i;
                    height: 48px $i;
                    background-color: #fff;
                    border: 1px solid #c8c8c8;
                    color: #858585;
                    padding: 0px 25px $i;

                    &::-webkit-input-placeholder {
                        /* Chrome/Opera/Safari */
                        color: #858585;
                    }

                    &::-moz-placeholder {
                        /* Firefox 19+ */
                        color: #858585;
                    }

                    &:-ms-input-placeholder {
                        /* IE 10+ */
                        color: #858585;
                    }

                    &:-moz-placeholder {
                        /* Firefox 18- */
                        color: #858585;
                    }
                }

                textarea {
                    padding-top: 15px;
                    height: 156px !important;
                }

                .btn {
                    margin: 0;
                    padding: 0;
                    outline: 0;
                    box-shadow: none;
                    border-radius: 0px;
                    background-color: $baseColor;
                    margin-top: -6px;

                    span {
                        color: #383838;
                        font-size: 21px;
                    }
                }
            }
        }
    }
}

body.ltr:not(.contact-us) {
    .fox-container {
        .fox-column {
            &.fox-column6 {
                width: 49% $i;
                margin-left: 6px $i;
                margin-right: 0;

                @include respond(768) {
                    width: 100% $i;
                    margin: 0 $i;
                }
            }
        }
    }
}

/*=====  End of Repeatable Site Elements  ======*/

//Side Let Us Help You
.side-let-us-help-you {
    position: fixed;
    top: 100px;
    right: 35px;
    z-index: 99;

    a {
        display: block;
        width: 100%;
        height: 100%;
        border-radius: 50%;
        background-color: #FBE0B2;
    }

    @include respond(768) {
        top: auto;
        right: auto;
        left: 4px;
        bottom: 4px;
        max-width: 40px;
    }
}